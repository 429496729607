import React from "react";
import lagerwall from '../../assets/JohaneLagerwall.jpg';
import './Hero.scss';

const Hero = () => {
    return (
        <div className="Hero">
            <div className="Hero__left">
                <div className="Hero__leftInner">
                    <h1>Hi,<br />I'm <span>Johan</span> and <br />I'm an <span>insurance consultant</span></h1>
                    <div>
                        <a className="Hero__link" href="mailto: lagerwallconsulting@gmail.com">Contact me</a>
                    </div>
                </div>
            </div>
            <div className="Hero__right" style={{ backgroundImage: `url(${lagerwall})` }}></div>
        </div>
    )
}

export default Hero;