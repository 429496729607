import React from "react";
import { FaRocketchat } from 'react-icons/fa';
import './Banner.scss';

const Banner = () => {
    return (
        <div className="Banner">
            <h3 className="Banner__text"><span className="Banner__icon"><FaRocketchat /></span> Coming up next</h3>
            <p className="Banner__text">
                Delighted to announce that I will attend the 64th Monte-Carlo Rendez Vous 10th – 14th September.<br />Looking forward to meeting you there.
            </p>
        </div>
    )
}

export default Banner;