import React from "react";
import { MdEmail } from 'react-icons/md';
import { BsLinkedin } from 'react-icons/bs';
import { AiFillPhone } from 'react-icons/ai';
import './Footer.scss';

const Footer = () => {
    return (
        <div className="Footer">
            <div className="Footer__inner">
                <p className="Footer__bold">Contact me:</p>
                <a className="Footer__link" href="mailto: lagerwallconsulting@gmail.com"><MdEmail /> lagerwallconsulting@gmail.com</a>
                <a className="Footer__link" href=""><BsLinkedin /> LinkedIn</a>
                <a className="Footer__link" href="tel:+4670764822"><AiFillPhone /> +46 (0)70-276 48 22</a>
            </div>
        </div>
    )
}

export default Footer;