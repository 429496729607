import React from "react";
import './Header.scss';

const Header = () => {
    return (
        <div className="Header">
            <span>Lagerwall Consulting</span>
        </div>
    )
}

export default Header;