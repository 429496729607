import React from "react";
import arias from '../../assets/arias.png';
import inrc from '../../assets/inrc.png';
import cv_pdf from '../../assets/pdf/cv.pdf'
import './Content.scss';

const Content = () => {
    return (
        <div className="Content">
            <div className="Content__inner">
                <div className="Content__left">
                    <h3 className="Content__h3">More than <br />40 years <br />of experience</h3>
                </div>
                <div className="Content__right">
                    <p className="Content__text">Senior Consultants for InterNational Re Consultants (INRC)</p>
                    <a href="http://internationalreconsultants.com/">
                        <img className="Content__img" src={inrc} alt="inrc logo" />
                    </a>
                    <p className="Content__text">Member of</p>
                    <a href="https://arias.org.uk">
                        <img className="Content__img" src={arias} alt="arias logo" />
                    </a>
                </div>
            </div>
            <div className="Content__inner">
                <div className="Content__full">
                    <h4 className="Content__h4">What I do</h4>
                    <p className="Content__text">
                        Underwriting expertise developed since 1973 in the following classes of business: marine hull, war, P&amp;I, credit &amp; bond, financial guarantees and political risks. Since 1993 involved in run-off of non-life reinsurance (all branches) including London Market LMX and APH from the US. Actively involved in a number of inspections concerning financial guarantees, credit &amp; bond and London Market business.
                    </p>
                    <p className="Content__text">
                        Responsible in Wasa Run-Off for new acquisitions of run-off companies/portfolios including being project leader of the due diligence process.
                    </p>
                    <p className="Content__text">
                        Approved auditor of Lloyds Coverholders in Scandinavia.
                    </p>
                    <p className="Content__text">
                        Do you want to know more? <span className="Content__text--primary Content__text--bold"><a href={cv_pdf} download="cv.pdf">Here is my full CV...</a></span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Content;